@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=PT+Serif:ital@1&family=Roboto:wght@400;500&display=swap');
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./assets/papier.jpg);
  background-repeat: repeat;
  transition: all .3s;
}


h1 {
  font-family: 'PT Serif', 'Times New Roman', serif;
  font-style: italic;
}

.text-input {
  padding: .6em .7em;
  border-radius: .35em;
}

.input-shadow {
  box-shadow: inset 0 .1em .3em rgba(0, 0, 0, .6);
  transition: all 0.4s;
}

.input-complete {
  background-color: transparent
}

.checkbox {

  border: 0 !important;
}

.checkbox:checked {
  background-color: #cc1414;
}

.banner {
  background: rgb(229,69,69);
  background: linear-gradient(90deg, rgba(229,69,69,1) 0%, rgba(204,20,20,1) 100%);

}

button {
  background: rgb(229,69,69);
  background: linear-gradient(180deg, #e54545 0%, #cc1414 100%);
  font-family: 'PT Serif', serif;
  padding: .25em 1em .4em 1em;
  color: #fff;
  font-size: 1.25em;
  font-style: italic;
  border-radius: .35em;
  box-shadow: 0 .1em .23em rgba(0, 0, 0, .5);
  transition: all .2s;
}

button:hover {
  background: rgb(259,99,99);
  background: linear-gradient(180deg, #f35353 0%, #d22323 100%);
}

button.disabled {
  background: rgb(100,100,100);
  background: linear-gradient(180deg, #d1d1d1 0%, #b1b1b1 100%);
}

.form-control1 {
  border: 3px solid rgba(0, 0, 0, 0)
}
.is-invalid1 {
  border: 3px solid rgb(229,69,69);
}

.invalid-feedback {
  color: rgb(229,69,69);
}

.transition {
  transition: all .5s;
}